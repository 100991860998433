<template>
  <b-col cols="12">
    <b-card class="px-2 py-1">
      <b-row>
        <h2> Consultar a mi cliente </h2>
      </b-row>
      <b-row class="mt-1 d-flex justify-content-between">
        <b-col
          lg="5"
          class="my-1"
        >
          <validation-observer ref="searchRucByEfideForm">
            <b-form
              @submit.prevent="searchByRuc"
            >
              <validation-provider
                #default="{ errors }"
                name="RUC"
                rules="required|min:11|ruc"
                vid="ruc"
              >
                <b-input-group>
                  <b-form-input
                    id="ruc"
                    v-model="ruc"
                    placeholder="Buscar pagadora por RUC"
                    name="ruc"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="secondary"
                      size="sm"
                      type="submit"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form>
          </validation-observer>
        </b-col>
        <b-col
          v-if="flagSearch"
          lg="6"
          class="my-1"
        >
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span class="mr-1"> RUC: </span>
              <span :class="`text-right text-wrap ${payer.ruc !== '' ? 'bold-text': ''}`"> {{ payer.ruc }} </span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span class="mr-1"> Razón Social: </span>
              <div class="d-flex">
                <span :class="`text-right text-wrap mr-1 ${payer.businessName !== '' ? 'bold-text': ''}`"> {{ payer.businessName }} </span>
                <div
                  v-if="payer.status === 'C'"
                  class="my-auto"
                >
                  <feather-icon
                    class="cpointer"
                    icon="EditIcon"
                    height="20"
                    width="20"
                    @click="openModalBusinessName"
                  />
                </div>
              </div>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span class="mr-1"> Estado: </span>
              <b-badge :variant="variantPayerStatus(payer.status)">
                {{ formatterPayerStatus(payer.status) }}
              </b-badge>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span class="mr-1"> Email: * </span>
              <div class="d-flex">
                <span class="text-right text-wrap mr-1 bold-text"> {{ payer.secondaryEmail === '' ? 'Click para ingresar' : payer.secondaryEmail }} </span>
                <div class="my-auto">
                  <feather-icon
                    class="cpointer"
                    icon="EditIcon"
                    height="20"
                    width="20"
                    @click="openModalEmail"
                  />
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-row class="justify-content-center">
            <b-button
              class="mt-2"
              variant="primary"
              type="button"
              :disabled="processing"
              @click="registerPayer"
            >
              <b-spinner
                v-if="processing"
                style="margin-bottom: 2px"
                small
              />
              {{ processing ? 'CARGANDO...' : 'Registrar pagador' }}
            </b-button>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          <p class="mb-0">
            En este campo puedes ingresar el Ruc de tu cliente y consultar si tiene la condición de aprobado para realizar el financiamiento de tus facturas o letras
          </p>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="px-2 py-1 mt-2">
      <h2> Mis clientes registrados </h2>
      <b-col
        cols="12"
        class="mt-2 px-0"
      >
        <p class="mb-0">
          En este campo puedes revisar que clientes tienes aprobados para realizar el financiamiento de tus facturas o letras
        </p>
      </b-col>
      <div
        v-if="payers.length===0"
        class="d-flex justify-content-center col-12 mt-3 mb-2"
      >
        <h3>
          Todavía no ha registrado ninguna pagadora para operar
        </h3>
      </div>
      <vue-good-table
        v-else
        :columns="columns"
        :rows="payers"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        class="mt-3 mb-2"
      >
        <div
          slot="emptystate"
          class="text-center my-5"
        >
          <h3
            v-if="payers.length===0"
          >
            Todavía no ha registrado ninguna pagadora para operar
          </h3>
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'status'">
            <b-badge
              :id="`tooltip-target-${props.row.id}`"
              :variant="variantPayerStatusV1(props.row.status)"
            >
              {{ formatterPayerStatusV1(props.row.status) }}
              <feather-icon
                v-if="props.row.status === 'notApproved'"
                icon="InfoIcon"
              />
            </b-badge>
            <b-tooltip
              v-if="props.row.status === 'notApproved'"
              :target="`tooltip-target-${props.row.id}`"
              triggers="hover"
              boundary-padding="10"
            >
              Pagadora en consulta, en breve un ejecutivo se contactará con usted
            </b-tooltip>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'actions'">
            <template v-if="props.row.lineStatus === 1 && props.row.status === 'approved'">
              <span :id="`tooltip-button-${props.row.id}`">
                <b-button
                  variant="primary"
                  class="mx-1"
                  disabled
                >
                  Solicitado
                  <feather-icon
                    icon="InfoIcon"
                  />
                </b-button>
                <b-tooltip
                  :target="`tooltip-button-${props.row.id}`"
                  triggers="hover"
                  boundary-padding="10"
                >
                  En breve un ejecutivo se contactará con Ud. para continuar con la ampliación de línea.
                </b-tooltip>
              </span>
            </template>
            <b-button
              v-else
              :variant="props.row.status === 'notApproved' ? 'danger' : 'primary'"
              class="mx-1"
              :disabled="props.row.status === 'notApproved' || props.row.status === 'rejected'"
              @click="onIncreasePayerLine(props.row)"
            >
              Ampliar
            </b-button>
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-center flex-wrap mt-1">
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <b-modal
      ref="modal-email"
      v-model="showModalEmail"
      title="Correo electrónico de la pagadora"
      hide-footer
      centered
      size="lg"
    >
      <b-row class="my-2 px-2 justify-content-center">
        <b-col
          md="10"
        >
          <validation-provider
            #default="{ errors }"
            name="correo electrónico"
            rules="email"
            vid="email"
          >
            <b-form-group
              label="Correo electrónico"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                :state="errors.length > 0 ? false:null"
                name="email"
                placeholder="Ejm: usuario@ejemplo.pe"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-1 mb-2 px-2 justify-content-end">
        <b-button
          variant="primary"
          type="button"
          @click="saveEmail"
        >
          Guardar
        </b-button>
      </b-row>
    </b-modal>
    <b-modal
      ref="modal-businessName"
      v-model="showModalBusinessName"
      title="Razón Social de la pagadora"
      hide-footer
      centered
      size="lg"
    >
      <b-row class="my-2 px-2 justify-content-center">
        <b-col
          md="10"
        >
          <validation-provider
            #default="{ errors }"
            name="Razón social"
            rules="required|max:100"
            vid="businessName"
          >
            <b-form-group
              label="Razón Social"
              label-for="businessName"
            >
              <b-form-input
                id="businessName"
                v-model="businessName"
                :state="errors.length > 0 ? false:null"
                name="businessName"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-1 mb-2 px-2 justify-content-end">
        <b-button
          variant="primary"
          type="button"
          @click="saveBusinessName"
        >
          Guardar
        </b-button>
      </b-row>
    </b-modal>
  </b-col>
</template>

<script>
import {
  BCol,
  BCard,
  BRow,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BListGroup,
  BListGroupItem,
  BModal,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCol,
    BCard,
    BRow,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BListGroup,
    BListGroupItem,
    BModal,
    BTooltip,
    VueGoodTable,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      processing: false,
      ruc: '',
      email: '',
      businessName: '',
      payer: {
        ruc: '',
        businessName: '',
        status: '',
        email: '',
        id: '',
        secondaryEmail: '',
      },
      payers: [],
      showModalEmail: false,
      showModalBusinessName: false,
      columns: [
        {
          label: 'RUC',
          field: 'ruc',
        },
        {
          label: 'Razón Social',
          field: 'businessName',
        },
        {
          label: 'Estado',
          field: 'status',
          tdClass: 'text-center',
          width: '200px',
        },
        {
          label: 'Ampliación de Línea',
          field: 'actions',
          tdClass: 'text-center',
          width: '250px',
        },
      ],
      pageLength: 5,
      flagSearch: false,
      required,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      clientRuc: JSON.parse(localStorage.getItem('userData')).ruc,
    }
  },
  async created() {
    await this.getPayers()
  },
  methods: {
    async searchByRuc() {
      const success = await this.$refs.searchRucByEfideForm.validate()
      if (success) {
        try {
          this.flagSearch = false
          const { data: { payer: payerData } } = await useJwt.searchRucByEfide({
            user: 'UsuarioPD',
            ruc: this.ruc,
          })

          if (payerData) {
            this.payer = {
              ruc: payerData.vcRuc,
              businessName: payerData.RazonSocialPagadora,
              status: payerData.Status,
              email: payerData.Email,
              id: payerData.IdPagadora,
              secondaryEmail: '',
            }
          } else {
            this.payer = {
              ruc: this.ruc,
              businessName: '',
              status: 'C',
              email: '',
              id: '',
              secondaryEmail: '',
            }
          }

          this.businessName = ''
          this.email = ''

          this.flagSearch = true
        } catch (error) {
          this.flagSearch = false
          console.log(error)
        }
      }
    },
    variantPayerStatus(status) {
      switch (status) {
        case 'C':
          return 'warning'
        case 'A':
          return 'success'
        case '2':
          return 'success'
        default:
          return 'secondary'
      }
    },
    variantPayerStatusV1(status) {
      switch (status) {
        case 'notApproved':
          return 'warning'
        case 'approved':
          return 'success'
        case 'rejected':
          return 'danger'
        default:
          return 'secondary'
      }
    },
    formatterPayerStatus(status) {
      switch (status) {
        case 'C':
          return 'En consulta'
        case 'A':
          return 'Aprobada'
        case '2':
          return 'Aprobada'
        default:
          return '-'
      }
    },
    formatterPayerStatusV1(status) {
      switch (status) {
        case 'notApproved':
          return 'En consulta'
        case 'approved':
          return 'Aprobada'
        case 'rejected':
          return 'Rechazada'
        default:
          return '-'
      }
    },
    openModalEmail() {
      this.showModalEmail = true
    },
    openModalBusinessName() {
      this.showModalBusinessName = true
    },
    hideModalEmail() {
      this.$refs['modal-email'].hide()
    },
    hideModalBusinessName() {
      this.$refs['modal-businessName'].hide()
    },
    saveEmail() {
      this.payer.secondaryEmail = this.email
      this.hideModalEmail()
    },
    saveBusinessName() {
      this.payer.businessName = this.businessName
      this.hideModalBusinessName()
    },
    async registerPayer() {
      this.processing = true
      const clientId = JSON.parse(localStorage.getItem('userData')).efideData ? JSON.parse(localStorage.getItem('userData')).efideData.clientId : ''
      const userId = JSON.parse(localStorage.getItem('userData'))._id
      try {
        await this.getPayers()
        const index = this.payers.findIndex(payer => payer.ruc === this.payer.ruc)
        if (index !== -1) {
          throw new Error('El pagador ya se encuentra registrado')
        }
        await useJwt.registerPayerByEfide({
          clientId,
          clientRuc: this.clientRuc,
          payerId: this.payer.id,
          ruc: this.payer.ruc,
          email: this.payer.email,
          businessName: this.payer.businessName,
          status: this.payer.status,
          secondaryEmail: this.payer.secondaryEmail,
          userId,
        })

        await this.getPayers()

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Éxito',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Se ha registrado el pagador',
            autoHideDelay: 8000,
          },
        })
        this.processing = false
      } catch (error) {
        console.log('errores', error)
        this.processing = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error al registrar pagador',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error.message || error.response.data.error,
            autoHideDelay: 8000,
          },
        })
      }
    },
    async getPayers() {
      const { data } = await useJwt.getMyPayers({
        ruc: this.clientRuc,
      })

      const payers = data.payers ? data.payers.map(payer => (
        {
          businessName: payer.businessName,
          ruc: payer.ruc,
          id: payer._id,
          email: payer.email ? payer.email : '',
          status: payer.status,
          payerId: payer.efideData ? payer.efideData.IdPagador : '',
          lineStatus: payer.lineStatus ? payer.lineStatus : 0,
        }
      )) : []

      this.payers = payers
    },
    async onIncreasePayerLine(data) {
      const { efideData } = JSON.parse(localStorage.getItem('userData'))
      const clientId = efideData ? efideData.clientId : ''

      try {
        const { data: { payerLine } } = await useJwt.requestIncreasePayerLine({
          user: 'UsuarioPD',
          clientId,
          payerId: data.payerId,
          payerMongoId: data.id,
        })

        await this.getPayers()

        if (payerLine[0].Status === 'OK') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Éxito',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'En breve un ejecutivo se contactará con Ud. para continuar con la ampliación de línea.',
            },
          },
          {
            timeout: 8000,
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
  },

}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
  .tooltip .arrow {
    display: none;
  }
</style>
<style scoped>
  .text-wrap {
    overflow-wrap: anywhere;
  }
  .cpointer {
    cursor: pointer;
  }
  .bold-text {
    font-weight: 600;
  }
</style>
